@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&display=swap");

.ourproducts-title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 33px;
  letter-spacing: 0.075em;
  color: #ffffff;
}
/* .products-des {
  padding-right: 40px;
  

} */

.product-bg{
  background-image: url("../../assets/company/product.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: fit-content;
}
.products_details {
  font-family:  "Rubik";
  font-weight: 300;
  font-style: normal;
  font-size: 10px;
  line-height: 140%;
  letter-spacing: 0.085em;
  color: #000000;
}
.pd{
  padding: 0 250px;
}
.products_points {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 0.035em;
  color: #ffffff;
  margin-top: 50px;
}
.advantageof_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 33px;
  letter-spacing: 0.075em;
  color: #000000;
}
.aa {
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 0;
  z-index: 100;
}



.slick-slide img {
  margin: auto;
}
.card-title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 131%;
  letter-spacing: 0.035em;
  text-transform: capitalize;
  color: #000000;
}
.card-description {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14.4545px;
  line-height: 217.5%;
  letter-spacing: 0.055em;
  text-transform: capitalize;
  color: #000000;
}
.card-subdescription {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14.4545px;
  line-height: 217.5%;
  letter-spacing: 0.055em;
  text-transform: capitalize;
  color: #000000;
  margin-top: 20px;
}
.card-detail {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14.4545px;
  line-height: 217.5%;
  letter-spacing: 0.055em;
  text-transform: capitalize;
  color: #000000;
  margin-top: 20px;
}



.our_quality {
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.19);
  border-radius: 13px;
  margin-top: 100px;
}
.quality_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 33px;
  letter-spacing: 0.075em;
}
.quality_des {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 16.4323px;
  line-height: 131.5%;
  letter-spacing: 0.065em;
}
.infrastructure_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 33px;
  letter-spacing: 0.075em;
}
.infrastructure_des {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 31px;
  letter-spacing: 0.085em;
  color: #000000;
}
.infrastructure_des1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 31px;
  letter-spacing: 0.085em;
  color: #ffffff;
}
.advantage_quotes {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 149%;
  letter-spacing: 0.085em;
}
@media only screen and (max-width: 2500px) {
  .infras{
    padding: 0 200px;
  }
}
@media only screen and (max-width: 1400px) {
  .card-title {
    font-size: 19px;
  }
  .infras{
    padding: 0 10px;
  }
}
@media only screen and (max-width: 1345px) {
  .products-des {
    padding-right: 20px;
  }
 
}
@media only screen and (max-width: 1242px) {
  .quality_des {
    font-size: 14px;
  }
  .quality_title {
    margin-top: 10px !important;
  }
}
@media only screen and (max-width: 1204px) {
  .products-des {
    margin-right: 10px !important;
    padding-right: 0px !important;
    margin-left: 20px;
  }
  .products_points {
    font-size: 18px;
    margin-top: 25px!important;
  }
  .products_details {
    font-size: 19px;
  }
  .pd{
    padding: 0 170px;
  }
}

@media only screen and (max-width: 1120px) {
 
  
  .products_details {
    font-size: 18px;
  }
  .products_points {
    font-size: 16px;
  }
  .quality_des {
    font-size: 12px;
  }
  .quality_title {
    font-size: 28px;
    padding-top: 20px !important;
  }
  .infrastructure_des {
    font-size: 17px;
  }
}
@media only screen and (max-width: 1000px) {
  .homepage_menu {
    margin-left: 0px !important;
  }
  .pd{
    padding: 0 120px;
  }
 
  .card-description {
    font-size: 13px;
  }
  .card-subdescription {
    font-size: 13px;
  }
  .card-detail {
    font-size: 13px;
  }
  .infrastructure_des {
    font-size: 15px;
  }
  .advantage_quotes {
    font-size: 18px;
  }
  .product-bg{
    padding: 30px 0px;

  }
}
@media only screen and (max-width: 992px) {
  .products-des{
    padding-top: 10px!important;
  }

  .ourproducts-title {
    padding: 0 10px;
    margin-top: 0px !important;
  }

  .Products_Points {
    margin-left: 10px;
  }
  .products_details {
    font-size: 16px;
  }
  .products_points {
    font-size: 15px;
  }

}
@media only screen and (max-width: 873px) {
  .quality_des{
    font-size: 10px;
    margin-top: 15px!important;
  }
  .quality_title{
    font-size: 25px;
    margin-top: 0px!important;
  }
  .advantage_quotes{
    margin-top: 0px!important;
  }
  .products-des{
    padding-top:25px!important;
  }
  .products_points{
    margin-top:25px;
  }
}

@media only screen and (max-width: 767px) {
  .ourproducts-title{
    font-size: 38px;
  }
  .products_details{
    font-size: 19px;

  }
  .products_points{
    margin-left: 80px;
    font-size: 16px;
  }
  .pd{
    padding: 0 20px;
  }
  .aa{
    margin-top: -50px!important;
  }
  .advantageof_title{
    font-size: 35px;
    margin-top: 0px!important;
    line-height: 45px;
  }
  .card_img{
    margin-top: 60px!important;
  }
  .infrastructure_des{
    line-height: 28px;
  }
  .infrastructure_title{
    padding-top: 0px!important;
    font-size: 38px;
  }
  .advantage_quotes{
    margin-top: 0px!important;
    padding-top: 20px!important;
  }
  .infras{
    padding: 0 0;
  }
  .in{
    font-size: 15px;
  }
}
@media only screen and (max-width: 610px) {
  .quality_des{
    font-size: 9px;
    margin-top: 5px!important;
  }
  .quality_title{
    padding-top: 10px!important;
  }
  .Quality_img{
    height: 240px;
  }
  .pd{
    margin-left: 0px!important;
  }
  .products_points{
margin-left: 60px!important;
  }
}
@media only screen and (max-width: 568px) {
  .ourproducts-title{
    font-size: 35px;
    margin-right: 17px!important;
  }
  .products_details{
    font-size: 16px;
margin-left: 20px!important;
  }
 
  .products_points{
   margin-left: 20px!important;
    margin-top: 20px!important;
    line-height: 130%;
  }
 
  .card-description{
    line-height: 25px;
  }
  .card-subdescription{
    line-height: 25px;

  }
  .card-readmore{
    line-height: 25px;

  }
  .Quality_img{
    height: 250px;
  }
  .quality_des{
    font-size: 8px;
    font-weight: 400;
  }
  .quality_title{
    font-size: 22px;
  }
  .infrastructure_des{
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    margin-top: 20px!important;
    margin-left: 14px!important;
  }
  .pd{
    padding-left: 20px!important;
  }
  .infrastructure_title{
    font-size: 35px;
  }
  .infrastructure_title{
    margin-top: 20px!important;
  }
  .advantage_quotes{
    font-size: 16px;
  }
  .pd{
    padding: 0 10px;
  }
  .products-des{
    padding-top: 20px!important;
  }
}


@media only screen and (max-width: 361px) {
  .ourproducts-title{
    font-size: 34px;
  }
  .infrastructure_title{
    font-size: 34px;
  }
  .products_details{
   
    font-size: 15px;

  }
  .Products_Points{
    padding-left: 30px;
    padding-right: 30px;
  }
  .products_points{
    margin-left: 10px;
    margin-top: 20px!important;
    line-height: 130%;
    font-size: 14px;
  }
  .card_img{
    margin-left: 30px!important;
  }
  .quality_des{
    margin-left: -20px;
  }
  .quality_title{
    margin-left: -20px;

  }
}
@media only screen and (max-width: 351px) {
  .quality_des{
    font-size: 7px;
  }
 .quality_title{
  font-size: 20px;
 }
.infrastructure_des{
  font-size: 12px;
  line-height: 20px;
}
.infrastructure_title{
  font-size: 26px;
}
}
@media only screen and (max-width: 310px) {
  .ourproducts-title{
    font-size: 33px;
  }
  .products_details{
    padding-left: 10px;
    padding-right: 10px;
    font-size: 13px;

  }
  .Products_Points{
    padding-left: 20px;
    padding-right: 20px;
  }
  .products_points{
    margin-left: 10px;
    margin-top: 20px!important;
    line-height: 130%;
    font-size: 12px;
  }
}