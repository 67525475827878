@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&display=swap");
.contact {
  background-image: url("../../assets/HomePage/contactmsg1.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.contactustitle {
  color: #ffffff;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 69.3604px;
  line-height: 82px;
  text-align: center;
  letter-spacing: 0.035em;
}
.contactform {
  height: 650px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(141, 31, 168, 0.17),
    0px -4px 8px rgba(255, 255, 255, 0.4);
  border-radius: 12px;
  margin-top: 100px;
  margin-bottom: 100px;
}
.des {
  padding-right: 100px;
}
.contactus_des {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 159%;
  letter-spacing: 0.05em;
  color: #373737;
}
.contactus_detail {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 129%;
  letter-spacing: 0.05em;
  color: #373737;
}
.text_area {
  width: 81%;
  height: 150px;
  background: #ffffff;
  border: 0.6px solid rgba(0, 0, 0, 0.22);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12);
  border-radius: 7px;
}
.text_area:focus {
  outline: "none" !important;
  outline-color: #3d94f0;
}
.attachment {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 129%;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color: #384D4B;
  display: inline-block;
  padding: 6px 5px;
  cursor: pointer;
}
.attachment:hover {
  color:#384D4B;
}
.file{
  font-size: 14px;
  font-family: "Rubik";
  font-style: normal;
}


.email_input .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 0.6px solid rgba(0, 0, 0, 0.22) !important;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12);
  border-radius: 7px;
}
.email_input
  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #1976d2 !important;
  border: 0.6px solid;
}
.contact_sendinquiry {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.035em;
  background: #384D4B!important;
  border-radius: 79.7674px !important;
  padding: 13px 25px !important;
  color: #ffffff;
  border: none;
}
.contact_sendinquiry:hover {
  background: #384D4B;
  border: none;
  color: #ffffff;
}
.contact_sendinquiry:focus {
  box-shadow: none;
  border: none;
}
.contactus_txt {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16.2538px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.035em;
  text-transform: capitalize;
  color: #000000;
}
.errormsg_txt {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #ff7070;
  position: absolute;
  top: -40px;
}
.Email {
  background-color: rgba(56, 56, 56, 0.1);
  width: 100%;
  position: absolute;
  z-index: 2;
  bottom: 0;
  top: 0;
  border-radius: 12px;
  text-align: center;
display: flex;
justify-content: center;
}


.emailsend{
  width: 260px;
  height: 200px;
 margin-top: 300px;
  background: #ffffff;
  border-radius: 32px;
  margin-left: -80px;
}
.emailanimation {
  width: 100%;
  height: 100px;
 
}
input[type="file"] {
  display: none;
}


.msg {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  letter-spacing: 0.035em;
}
.note_msg{
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  letter-spacing: 0.035em;
  margin-top: -10px;
}

@media only screen and (max-width: 1230px) {
  .contactustitle {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  .contactform {
    margin-top: 80px;
  }
  
}

@media only screen and (max-width: 1150px) {
  .contactform {
    margin-top: 60px;
  }
  .contactustitle {
    font-size: 49px;
  }
 
 
}
@media only screen and (max-width: 1050px) {
  .contactform {
    margin-top: 40px;
  }
 
}
@media only screen and (max-width: 960px) {
  .contactform {
    margin-top: 20px;
  }
  .contactustitle {
    font-size: 45px;
  }
  .errormsg_txt {
    font-size: 16px;
  }
  .emailsend{
    margin-left: -50px;
    }
}

@media only screen and (max-width: 769px) {
  .contactus_des {
    padding-top: 20px !important;
    font-size: 18px;
  }
  .contactus_detail {
    font-size: 11px;
  }
  .contactus_txt {
    font-size: 13px;
  }

  .contactform {
    margin-top: 0px !important;
  }
  .contactustitle {
    margin-top: 0px !important;
    padding-top: 0px !important;
    font-size: 30px;
  }
  .contacttitle {
    margin-top: 0px !important;
  }
  .errormsg_txt {
    font-size: 15px;
    padding-left: 5px !important;
  }
  .emailsend{
    margin-top: 200px;
    }
    .errormsg_txt{
      font-size: 13px;
    }
}
@media only screen and (max-width: 576px) {
  .email {
    margin-left: 16px !important;
    margin-top: 30px;
  }
  .contactform {
    height: 700px;
  }
  .errormsg_txt {
    font-size: 15px;
    padding-left: 10px !important;
  }
  .emailsend{
    margin-top: 300px;
    }
   
    
}

@media only screen and (max-width: 485px) {
  .contactus_des {
    font-size: 15px;
    padding-left: 25px !important;
  }
  .contactus_detail {
    font-size: 8px;
    padding-left: 25px !important;
  }
  .contactus_txt {
    font-size: 10px;
  }
  .attachment {
    margin-left: 24px !important;
  }
  .note_msg {
    margin-left: 24px !important;
    font-size: 10px;
  }
  .text_area {
    margin-left: 25px !important;
  }
  .EMAIL {
    margin-left: 0px !important;
  }

  .contactform {
    margin-top: 0px !important;
  }
  .contactustitle {
    margin-top: 0px !important;
    padding-top: 0px !important;
    font-size: 25px;
  }
  .contacttitle {
    margin-top: -30px !important;
  }
  .des {
    padding-right: 10px !important;
  }
  .errormsg_txt {
    font-size: 11px;
    padding-left: 0px !important;
  }
  
  .emailsend{
    margin-left: -20px;
    }
}
@media only screen and (max-width: 400px) {
 
  .emailsen{
    margin-left: 280px!important;
    }
}


@media only screen and (max-width: 330px) {
  .contactus_des {
    font-size: 12px;
  }
  .contactus_detail {
    font-size: 7px;
  }
  .contactus_txt {
    font-size: 9px;
  }
  .contactustitle {
    font-size: 17px;
  }
  .contacttitle {
    margin-top: -40px !important;
  }

  .des {
    padding-right: 5px !important;
  }
 
  .emailsen{
    margin-left: 270px!important;
    }
}
