@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

.ourproducts {
  padding: 0px 200px;
}
.product_title {
  color:#384D4B;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 129%;
}
.product_img{
  width: 100%;
}
.zoom-img {
  transition: transform 0.3s; /* add a transition for the zoom effect */
  height: 250px;
  width: 100%;
  border-radius: 12px;
}

.zoom-img:hover {
  transform: scale(0.9); /* zoom in by 20% when hovering over the image */
}
.img-container {
  position: relative; /* necessary for absolute positioning of the text */
}
.hover-text {
  position: absolute; /* position the text on top of the image */
  top: 50%; /* center the text vertically */
  left: 50%; /* center the text horizontally */
  transform: translate(-50%, -50%); /* adjust the position of the text */
  background-color: transparent; /* give the text a semi-transparent black background */
  padding: 10px 10px; /* add some padding to the text */
  border-radius: 5px; /* give the text rounded corners */
  visibility: hidden; /* hide the text by default */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.035em;
  text-transform: capitalize;
  cursor: pointer;
  width: 80%;
}
.hover-text1 {
  position: absolute; /* position the text on top of the image */
  top: 60%; /* center the text vertically */
  left: 50%; /* center the text horizontally */
  transform: translate(-50%, -50%); /* adjust the position of the text */
  background-color: transparent; /* give the text a semi-transparent black background */
  padding: 10px 10px; /* add some padding to the text */
  border-radius: 5px; /* give the text rounded corners */
  visibility: hidden; /* hide the text by default */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.035em;
  text-transform: capitalize;
  cursor: pointer;
  width: 80%;
}

.hover-text:hover {
  text-decoration: underline;
}
.img-container:hover .hover-text {
  visibility: visible; /* show the text when hovering over the image */
  color:#384D4B;
}
.img-container:hover img {
  opacity: 0.3;
  background-color: rgb(18, 18, 18, 0.2);
}

.hover-text1:hover {
  text-decoration: underline;
}
.img-container:hover .hover-text1 {
  visibility: visible; /* show the text when hovering over the image */
  color:#384D4B;
}

@media only screen and (max-width: 1479px) {
  .pimg_3 {
    margin-bottom: 26px !important;
  }
  .ourproducts {
    padding: 20px 40px;
  }
}
@media only screen and (max-width: 1250px) {
  .img_1 {
    margin-bottom: 0px !important;
  }
  .zoom-img {
    width: 250px;
  }
  .pimg_2 {
    padding-left: 15px;
  }
}
@media only screen and (max-width: 1190px) {
  .ourproducts {
    padding: 20px 20px;
  }
  .pimg_2 {
    padding-left: 15px;
  }
  .hover-text {
    font-size: 18px;
  }
  .hover-text1 {
    font-size: 18px;
  }
  .zoom-img {
    width: 88%;
  }
}



@media only screen and (max-width: 945px) {
  .zoom-img {
    height: 200px;
  }
  .hover-text {
    font-size: 14px;
    padding: 10px 0px;
  }
  .hover-text1 {
    font-size: 14px;
    padding: 10px 0px;
  }
  .product_img {
    margin-left: 10px !important;

  }
  .ourproducts {
    padding: 20px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .zoom-img {
  width: 90%;
  }
  .hover-text {
    font-size: 17px;
    padding: 10px 0px;
  }
  .hover-text1 {
    font-size: 17px;
    padding: 10px 0px;
  }
  .mtimg{
    margin-top: 24px;
   
  }
 
  
}


@media only screen and (max-width: 425px) {
  .ourproducts {
    padding: 0px 0px !important;
    margin-top: 30px !important;
  }
  .product_img {
    margin-left: 5px !important;
  }
  .product_title {
    font-size: 33px;
  }
  .zoom-img {
    height: 100px;
  }
  .hover-text {
    font-size: 12px;
  }
  .hover-text1 {
    font-size: 12px;
    line-height: 18px;
    top: 65%;
  }
  .pro{
    margin: 0 20px;
  }
 
 
}


@media only screen and (max-width: 310px) {
  .product_title {
    font-size: 24px;
  }
 
}
