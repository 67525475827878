@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&display=swap");
.footer {
  background:#384d4b;
  margin-top: 250px;
}
.company_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 38px;
  color: #ffffff;
}
.footer_txt {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 20px;
}
.links {
  padding: 0px 0px;
  justify-content: center;
  
}
.footer_content {
  padding: 0 60px;
  position: relative;
  bottom: 70px;
}
.navbar_list {
  display: inline-block;
  padding: 0px 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.035em;
  color: #ffffff;
  position: relative;
  bottom: 15px;
}
.navbar_list:hover {
  text-decoration: underline;
}

.contact_card {
  background-image: url("../../assets/HomePage/contact1.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
  bottom: 150px;
  width: 1200px;
  flex-direction: row;
}

.CALL {
  color: #384D4B;
  margin-top: -6px;
}
.callicon {
  cursor: pointer;
}
.contactcard_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 51px;
}
.contactcard_address {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
}
.contactcard_addr {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
}

.contactus_btn {
  border-color: #384D4B !important;
  background: #384D4B !important;
  border-radius: 70px;
  padding: 8px 30px;
  margin-bottom: 20px;
  position: relative;
  bottom: 20px;
}
@media only screen and (max-width: 1280px) {
  .contactcard_title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1115px) {
  .contactcard_address {
    font-size: 15px;
    line-height: 20px;
    padding-bottom: 30px;
  }
  .dealer {
    position: relative;
    top: 30px;
  }
  .contactcard_des {
    position: relative;
    top: 20px;
  }
  .contactcard_tit {
    padding-top: 0px !important;
  }
}
@media only screen and (max-width: 1058px) {
  .company_title {
    font-size: 25px;
  }
  .contactcard_title {
    font-size: 26px;
  }
  .contactcard_address {
    font-size: 12px;
    line-height: 18px;
  }
  .contactcard_addr {
    font-size: 13px;
    line-height: 18px;
  }
  .contactcard_tit {
    font-size: 18px;
  }
  .contactus_btn {
    padding: 7px 25px;
    position: relative;
    bottom: 40px;
  }
  .footer_txt {
    font-size: 17px;
  }
  .navbar_list {
    padding: 0 10px;
    font-size: 17px;
  }
  .footer_content {
    padding: 0 40px;
  }
  .contactcard_des {
    margin-left: 130px;
  }
}
@media only screen and (max-width: 940px) {
  .contactcard_des {
    margin-left: 30px !important;
    margin-top: 60px !important;
  }
  .navbar_list {
    font-size: 13px;
  }
  .footer_txt {
    font-size: 16px;
  }
  .dealer {
    margin-right: 10px !important;
    position: relative;
    top: 70px;
  }
  .contactus_btn {
    position: relative;
    bottom: 50px;
    padding: 6px 17px;
    font-size: 12px;
  }
  .contactcard_tit {
    font-size: 15px;
    margin-top: 0px !important;
  }
  .contactcard_title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .contact_card {
    background-size: 140% 100%;
  }
  .footer_txt {
    padding: 0px 0px;
    text-align: center;
  }
  .company_title {
    text-align: center;
  }
  .footer_content {
    position: relative;
    bottom: 80px;
  }
  .navbar_list {
    text-align: center;
    padding: 10px 20px;
    margin-top: 0px !important;
    margin-left: 0px !important;
  }
  .link {
    display: flex;
    justify-content: center;
    
  }


  .contactcard_address {
    font-size: 12px;
  }
  .contactcard_addr {
    font-size: 12px;
  }
  .contactcard_des {
    margin-left: 10px!important;
    padding: 0 0!important;
    position: relative;
    top: 5px;
  }
  .call_no {
    font-size: 12px;
  }
  .CALL {
    font-size: 19px !important;
  }
  .dealer{
    margin-right: 20px!important;
    margin-top: 15px!important;
  }
  .contactcard_tit{
    font-size: 12px;
  }
  .contactcard_title{
    margin-top: 0px!important;
  
  }
  .contactcard_address{
    font-size: 10px;
    line-height: 14px;
    font-weight: 500;
  }
  .contactus_btn {
    position: relative;
    bottom: 40px;
   
  }
  

}

@media only screen and (max-width: 590px) {
  .contactcard_title {
    font-size: 20px;
   
  }
  .contactcard_tit {
    font-size: 10px;
  }
  .contactcard_address {
    font-size: 9px;
    line-height: 12px;
  }
  .contactcard_addr {
    font-size: 11px;
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  .callicon1 {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  .contactcard_des {
    padding: 0px 0px;
    margin-left: 5px!important;
  }
  .company_title {
    font-size: 23px;
  }
.call_no{
  font-size: 9px;
  font-weight:bolder;
}
  .contactus_btn {
    padding: 5px 17px;
    font-size: 11px;
    position: relative;
    bottom: 55px;
  }
}

@media only screen and (max-width: 493px) {

  .contact_card {
    background-size: 180% 100%;
  }
  .contactus_btn {
   
    padding: 4px 13px;
    font-size: 10px;
  }
  .contactcard_title {
    font-size: 19px;
  }
  .contactcard_address {
    font-size: 7px;
    font-weight: 500;
    line-height: 10px;
  }
  .contactcard_addr {
    font-size: 7px;
    font-weight: 500;
    line-height: 10px;
  }
  .contactcard_tit {
    font-size: 10px;
  }
  /* .call_no {
    font-size: 7px;
  }
  .CALL {
    font-size: 13px !important;
  }
  .callicon1 {
    margin-top: 12px !important;
  }
  */

  .footer_txt {
    font-size: 14px;
    line-height: 20px;
  }
  .company_title {
    font-size: 17px;
  }

  .navbar_list {
    padding: 8px 6px;
    font-size: 12px;
    margin-left: 0px !important;
  }

  
  .links{
    height: 250px;
  }
}

@media only screen and (max-width: 395px) {
.contactcard_tit{
  font-size: 9px;
}
  .navbar_list {
    font-size: 10px;
  }
  .contact_card {
    background-size: 200% 100%;
    position: relative;
    bottom: 230px!important;
  }
  .call_no {
    font-size: 7px;
    position: relative;
    bottom: 3.5px;
    right: 2px;
  }
  .links{
    height: 200px;
  }
 .CALL{
  font-size: 15px!important;
 }
}
