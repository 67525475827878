.company {
  background: #f5f5f5;
}

.about_delmen {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 147%;
  letter-spacing: 0.075em;
  color: #000000;
}
.Delmen {
  padding: 20px 0px;
}
.about_delmen1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 147%;
  text-align: center;
  letter-spacing: 0.075em;
  color: #000000;
}
.about_delmen2 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 147%;
  text-align: center;
  letter-spacing: 0.075em;
  text-transform:uppercase;
  color: #000000;
}
.advantages_points {
  list-style: none;
  font-size: 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
text-align: start;
margin-top: 7px;
}


.advantages_points::before {
  content: '\2022'; /* Unicode character for a bullet point */
  display: inline-block;
  width: 1em; /* Adjust the width to control spacing between the bullet and text */
  
}

.advatanges_txt{
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 147%;
  letter-spacing: 0.075em;
  text-transform:uppercase;
  color: #80069E;
}
.design_1 {
  margin-top: 600px;
}
.Mesh_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 19.351px;
  line-height: 143.5%;
  text-align: center;
  letter-spacing: 0.075em;
  text-transform: capitalize;
  color: #000000;
}
.Products_Details {
  margin-top: 260px;
}
.aboutproduct_txt {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0.075em;
  text-transform: capitalize;
  color: #000000;
  margin-top: 20px;
}
.aboutproduct_txt1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 143.5%;
  letter-spacing: 0.075em;
  text-transform: capitalize;
  color: #000000;
  margin-left: 5px;
}
.headoffice_box {
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.13);
  border-radius: 21px;
  height: 380px;
  margin: 0px 130px;
}
.OurBranch_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 129%;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #8d1fa8;
}
.office_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 129%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #454545;
}

.contactperson {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12.1616px;
  line-height: 129%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000000;
  
}
.contactperson1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12.1616px;
  line-height: 129%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000000;
  
}


.Company_Name {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18.1231px;
  line-height: 129%;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000000;
}
.location_txt {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 129%;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000000;
  padding: 0px 200px;
}
.container-fluid .slick-next:before {
  color: white !important;
  background-color: #8d1fa8;
  position: absolute;
  bottom: 20px;
}
.container-fluid .slick-prev:before {
  color: white !important;
  background-color: #8d1fa8;
  position: absolute;
  bottom: 23px;
  left: -10px;
}
.container-fluid button.slick-next:before {
  border-radius: 50% !important;
  font-size: 25px;
}
.container-fluid button.slick-prev:before {
  border-radius: 50% !important;
  font-size: 25px;
}

.container-fluid button.slick-arrow {
  color: #8d1fa8 !important;
  border-radius: 100% !important;
}

@media only screen and (max-width: 1140px) {
  .location_txt {
    font-size: 13px;
    padding: 0 100px;
  }
  .contactperson {
    font-size: 11px;
  }
}
@media only screen and (max-width: 1000px) {
  .about_delmen {
    font-size: 17px;
  }
  .about_delmen1 {
    font-size: 17px;
  }
  .design {
    margin-top: 0px !important;
  }
  .Mesh_title {
    font-size: 15px;
  }
  .aboutproduct_txt1 {
    font-size: 16px;
  }
  .aboutproduct_txt {
    font-size: 18px;
  }
  .Products_Details {
    margin-left: 40px !important;
    margin-top: 220px;
  }
  .location_txt {
    padding: 0 50px;
  }
  .contactperson {
    font-size: 7px;
  }
  .contactperson1{
    font-size: 7px;
    margin-right: 0px !important;
  }
  .office_title{
    font-size: 19px;
  }
}
@media only screen and (max-width: 760px) {
  .about_delmen {
    font-size: 17px;
    margin-top: 0px !important;
  }
  .about_delmen1 {
    font-size: 17px;
  }

  .design_1 {
    margin-top: 660px;
  }
  .Mesh_title {
    font-size: 11px;
  }
  .aboutproduct_txt1 {
    font-size: 12px;
  }
  .aboutproduct_txt {
    font-size: 14px;
  }
  .Products_Details {
    margin-top: 100px;
  }
  .Glassmesh {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }

  .location_txt {
    padding: 0 10px;
  }
  .OurBranch_title {
    margin-top: 0px !important;
  }
  .contactperson {
    font-size: 6px;
  }
  .contactperson1{
    font-size: 6px;
    padding-right: 39px!important;
  }
  .office_title{
    font-size: 18px;
  }
  .location_txt{
    font-size: 12px;
  }
  .headoffice_box{
    margin: 0 50px;
  }
}

@media only screen and (max-width: 576px) {
  .about_delmen {
    font-size: 15px;
    padding-top: 10px !important;
  }
  .about_delmen1 {
    font-size: 15px;
    margin-top: 20px !important;
  }
  .about_delmen2 {
    font-size: 19px;
  }
  .advantages_points{
   margin-top: 15px!important;
  
  }
  .advatanges_txt{
    text-align: center;
  }
 
  .design_1 {
    margin-top: 640px;
  }
  .Mesh_title {
    font-weight: 400;
  }
  .aboutproduct_txt1 {
    font-weight: 400;
  }
  .aboutproduct_txt {
    font-weight: 500;
  }
  .Details_Img {
    width: 90%;
  }
  .Products_Details {
    padding: 0px 5px;
    margin-top: 50px !important;
  }
  .aboutproduct_txt1 {
    font-size: 15px;
  }
  .aboutproduct_txt {
    font-size: 17px;
  }
  .headoffice_box{
    margin: 0 20px;
  }
  .office_title{
    font-size: 16px;
  }
  .Company_Name{
    font-size: 17px;
  }
  .contactperson{
    display: none;
  }
  .contactperson1{
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .design_1 {
    margin-top: 780px;
  } 
  .about_delmen2 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 414px) {
  .about_delmen1 {
    font-size: 9px;
    font-weight: 400;
  }
  .about_delmen {
    font-size: 9px;
    font-weight: 400;
    margin-top: 0px!important;
  }
  .about_delmen2 {
    font-size: 15px;
    margin-top: 26px!important;
  }
  .design_1 {
    margin-top: 500px;
  }
  .Delmen {
    padding: 5px 0px;
  }
  .Mesh_title {
    font-size: 10px;
  }
  .Products_Details {
    padding: 0px 0px;
    margin-left: 16px !important;
    margin-top: 50px !important;
  }
  .aboutproduct_txt {
    font-size: 15px;
  }
  .aboutproduct_txt1 {
    font-size: 14px;
  }
  .location_txt{
    font-size: 10px;
  }
  .Company_Name{
    font-size: 13px;
  }
  .headoffice_box{
    margin: 0 10px;
    height: 325px;
  }
 
}
@media only screen and (max-width: 320px) {
  .about_delmen {
    font-size: 8px;
  }
  .about_delmen1 {
    font-size: 8px;
  }
  .Mesh_title {
    font-size: 8px;
  }
  .aboutproduct_txt {
    font-size: 12px;
  }
  .aboutproduct_txt1 {
    font-size: 11px;
  }
  .Company_Name{
    font-size: 10px;
  }
  .location_txt{
    font-size: 8px;
  }
  .headoffice_box{
    height: 300px;
  }
}
