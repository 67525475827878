.Nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.NAVBAR_1{
  padding: 0px 40px;
}
.Logo {
  text-align: start;
margin-left: 10px;
}
.del-logo1{
  width: 120px;
  height: auto;
  text-align: start;
 }
a {
  text-decoration: none; 
}

a:hover {
  text-decoration: none; 
}

.Inquiry_btn:hover {
  color: rgb(250, 249, 249);
  text-decoration: none; 

}

.Inquiry_btn {
  border-radius: 32px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12.2636px;
  line-height: 17px;
  background-color:#384D4B;
  padding: 5px 10px;
  color: rgb(255, 255, 255);
  text-decoration: none; 
  outline: none;
}
.Menu li.no-line::after {
  display: none;
}
.Menu > li,
.Menu > a {
  display: inline-block;
}
.Menu{
  display: flex;
  justify-content: flex-end; 
  padding: 0;

}
.Menu  a {
 
  transition: background-color 0.22s ease, color 0.22s ease;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;


  color:#384D4B;
}

.Menu li {
  padding: 0 20px;
  position: relative;}


.SubMenu {
  position: absolute;
  left: 33%;
  transform: translateX(-50%);
  display: none;
  padding: 10px 0;
  width: 180px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  margin-top: 6px;
  border-top: 2px solid #384D4B;
  background: #384D4B;
  z-index: 99;
  
}
.SubMenu li {
  list-style: none;
}

.SubMenu a {
  display: block;

  color: #ffffff;
  padding: 4px 0px; /* Adjust padding to ensure uniform spacing */
  font-size: 12px;
  text-align: center;
}
.Menu li:hover > .SubMenu {
  display: block;
}

.Submenu_2 {
  width: 200px; /* Define a specific width */

  position: absolute;
  top: 0px;
  left: 103%; /* Aligns next submenu to the right of the first submenu */
  display: none; /* Hidden by default */
  padding: 10px 0px;
  background: #384D4B;
  box-shadow: 0px -2px 8px rgba(255, 255, 255, 0.53);
  border-radius: 3px;
  width: 100%;
}
.Submenu_2 a {

  color: #ffffff;
  display: block;
  padding: 5px 0px;
  font-size: 11px;
  width: 100%;
}
.Submenu_2 li {
  padding: 3px 2px;
}

.SubMenu li:hover > .Submenu_2 {
  display: block;
}

.SubMenu li:hover,
.Submenu_2 li:hover {
  background-color: #ffffff4f;
}

/* Remove default list item indicators */
.SubMenu li::after,
.Submenu_2 li::after {
  content: none;
}

/* Remove line after specific menu item */
.Menu li.no-line::after {
  display: none;
}

.Menu > li:after  {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 3px;
  left: 50%;
  background-color:  #384D4B;
  transform: translateX(-50%);
  transition: width 0.3s ease-in-out;
  border-radius: 25px;

}
/* .Menu .Contacttrans {
  padding: 2px 20px;
} */

.Menu > li:hover:after {
  width: 90%;
}
/* mobiloe navbar */

.Menu_icon {
  color: #384D4B;
}
.Topnavbar {
  background-color: #384D4B;
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease-in-out;
  z-index: 2;
  cursor: pointer;
  /* position: absolute; */
  width: 100%;
}

.sub-Menu {
  background-color: #ffffff;
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease-in-out;
  width: 100%;
  box-shadow: 0px -2px 8px rgba(255, 255, 255, 0.53);
}
.Topnavbar.open {
  height: auto;
  overflow-y: scroll;
}
.nav-item span {
  display: flex;
  justify-content:flex-end;
  text-align: end;
}
.Menuopenicon {
  position: absolute;
  bottom: 160px;
  font-size: 36px !important;
  color: #ffffff;
}

.sub-Menu.open {
  height: 175px;
}
.menuopen_icon {
  transform: rotate(180deg);
}

.topnavbar-nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.subMenu-item {
  list-style-type: none;
 
}
.Submenu-link {
  display: block;
  color: #384D4B!important;
  padding: 15px 20px;
  text-decoration: none;
  transition: background-color 0.22s ease, color 0.22s ease;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
}

.Nav-link {
  display: block;
  color: #ffffff !important;
  padding: 35px 20px;
  text-decoration: none;
  transition: background-color 0.22s ease, color 0.22s ease;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 10px;
  margin-top: 0px;
}

.Nav-link:hover {
  text-decoration: underline;
}



@media only screen and (max-width: 1177px) {
  .Menu > a {
    font-size: 11px;
    padding: 20px 15px;
  }
  .SubMenu a {
    font-size: 10px;
    padding: 5px 0px;
  }
  .Submenu_2 a {
    font-size: 9px;
  }
  .names {
    font-size: 13px;
  }

  .Inquiry_btn {
    margin-left: 0px !important;
    font-size: 12px;
  }
  /* .Logo {
    font-size: 16px;
  } */
}
@media only screen and (max-width: 1000px){
  .NAVBAR_1{
    padding: 0px 20px;
  }
}
@media only screen and (max-width: 700px){
  .Logo {
    text-align: start;
  margin-left: 0px;
  }
  .del-logo1{
    width: 100px;
    height: auto;
    text-align: start;
   }
}




@media only screen and (max-width: 395px) {
  /* .Logo {
    font-size: 12px;
    line-height: 15px;
  } */
  .Menu_icon {
    font-size: 23px !important;
  }
}
@media only screen and (max-width: 300px) {
  /* .Logo {
    font-size: 11px;
    line-height: 13px;
  } */
}
