@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap");
.about_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 129%;
  letter-spacing: 0.05em;
  color:#384D4B;
}
.about_content {
  width: 79%;
}
.about_des {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.06em;
}
.about_des1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.06em;
}

.readmore_btn {
  border-color: #384D4B !important;
  background:#384D4B !important;
  border-radius: 70px;
  padding: 10px 35px;
}

@media only screen and (max-width: 1210px) {
  .about_content {
    width: 90%;
  }
  .aboutus{
    padding-left: 20px;
  }
  .about_des {
    font-size: 17px;
  }
  .about_des1 {
    font-size: 17px;
  }
}

@media only screen and (max-width: 1000px) {
  .about_des {
    font-size: 16px;
  }
  .about_des1 {
    font-size: 16px;
  }
  .aboutus{
    margin-top: 0px!important;
  }
}

@media only screen and (max-width: 900px) {
  .about_des {
    font-size: 15px;
  }
  .about_des1 {
    font-size: 15px;
  }
  .forcontact_details {
    margin-top: -5px !important;
  }
  .readmore_btn {
    margin-top: 20px !important;
  }
 
  .about_title {
    font-size: 35px;
    margin-top: 25px !important;

  }
}
@media only screen and (max-width: 760px) {
  .about_des {
    font-size: 12px;
  }
  .about_des1 {
    font-size: 12px;
  }
  .readmore_btn {
    font-size: 13px;
  }
 .about_title{
  margin-top: 10px !important;

 }
}
@media only screen and (max-width: 600px) {
  /* .about_des1 {
    display: none;
  } */
  .about_des {
    font-size: 12px;
  }
   .about_des1 {
    font-size: 12px;
  }
  .readmore_btn {
    font-size: 13px;
  }
  .aboutus {
    margin-top: 0px !important;
    padding-top: 0px!important;
  }
}
@media only screen and (max-width: 500px) {
  .aboutus {
    margin-top: 0px !important;
    margin-left: 0px!important;
  }
  .about_des {
    font-size: 10px;
    font-weight: 400;
  }
  .about_des1 {
    font-size: 10px;
    font-weight: 400;

  }
  .about_content {
    margin-top: 10px !important;
    width: 94%;
  }
  .readmore_btn {
    font-size: 11px;
    padding: 7px 20px;
    margin-top: 10px !important;
  }
  .about_title {
    font-size: 30px;
    margin-top: 0px !important;

  }
  
}

@media only screen and (max-width: 389px) {
  .about_des {
    font-size: 8px;
  }
  .about_title {
    font-size: 24px;
  }
  .about_des1 {
    font-size: 8px;
  }
  .weare{
    padding-top: 1px;
    margin-top: 20px!important;
  }
}
