@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&display=swap");

.retractable {
  background: #f5f5f5;
}
.RollerDr {
  background: #f5f5f5;
}
.CARDS {
  background-color: white;
  margin-top: 100px;
}
.CARDS_1 {
  background-color: white;
  margin-top: 100px;
}
.CARDS_2 {
  background-color: white;
  margin-top: 100px;
  padding-bottom: 200px;
}
.CARDS_3 {
  background-color: white;
  margin-top: 100px;
  padding-bottom: 200px;
}
.CARDS_4 {
  background-color: white;
  margin-top: 100px;
  padding-bottom: 50px;
}
.Card_1 {
  width: 87%;
}
.Card_1 img {
  height: 870px;
}
.Product_Detail {
  position: absolute;
  left: -40px;
}

.share_txt {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  color: #000000;
}
.share {
  background: rgba(238, 171, 255, 0.63);
}
.facebook {
  color: #0062e0;
  font-size: 30px !important;
}
.twitter {
  color: #4099ff;
  font-size: 30px !important;
}
.linkedin {
  color: #007bb6;
  font-size: 30px !important;
}
.whatsapp {
  color: #20b038;
  font-size: 30px !important;
}
.Product_Name {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 28.5271px;
  line-height: 202.5%;
  letter-spacing: 0.035em;
  text-transform: capitalize;
  color: #000000;
}
.Square {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 202.5%;
  letter-spacing: 0.035em;
  text-transform: capitalize;
  color: #7d019c;
}
.Square_txt {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 202.5%;
  letter-spacing: 0.035em;
  text-transform: capitalize;
  color: #000000;
}

.Price1 {
  position: absolute;
  bottom: 630px;
}
.Price2 {
  position: absolute;
  bottom: 605px;
}
.Price3 {
  position: absolute;
  bottom: 580px;
}
.Price4 {
  position: absolute;
  bottom: 815px;
}
.Price5 {
  position: absolute;
  bottom: 840px;
}
.Price6 {
  position: absolute;
  bottom: 716px;
}
.price_btn {
  padding: 16px 30px !important;
  border: 1px solid #7d019c !important;
  border-radius: 58.5714px !important;
  color: #7d019c !important;
  text-transform: capitalize !important;
  font-family: "Rubik" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 12px !important;
  letter-spacing: 0.035em !important;
  margin-top: -15px !important;
}

.product_des {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 202.5%;
  letter-spacing: 0.035em;
  text-transform: capitalize;
  color: #000000;
}
.Product_Title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 136%;
  letter-spacing: 0.045em;
  text-transform: capitalize;
  color: #000000;
}

.Product_Exn {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 17.15px;
  line-height: 145.5%;
  letter-spacing: 0.045em;
  color: #000000;
  padding-right: 20px;
}
.Product_Feature {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 136%;
  letter-spacing: 0.045em;
  color: #000000;
}
.Product_req {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 136%;
  letter-spacing: 0.045em;
  color: #000000;
}
.product_customize {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 136%;
  letter-spacing: 0.045em;
  color: #000000;
}
.Product_Points {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 17.15px;
  line-height: 26px;
  letter-spacing: 0.045em;
  color: #000000;
  margin-top: 10px;
}
.callback_btn {
  padding: 15px 60px !important;
  border: 1px solid #384D4B !important;
  border-radius: 47px !important;
  color:#384D4B !important;
  font-family: "Rubik" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 23px !important;
  letter-spacing: 0.035em !important;
  text-transform: uppercase !important;
}
.callback_btn:hover {
  color: #384D4B;
}
.ENQUIRY_btn {
  padding: 15px 100px !important;
  background:#384D4B!important;
  border-radius: 47px !important;
  font-family: "Rubik" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 23px !important;
  letter-spacing: 0.035em !important;
  text-transform: uppercase !important;
  color: #ffffff !important;
}
.ENQUIRY_btn:hover {
  color: #ffffff;
}
.Pricecard {
  width: 60%;
  height: 470px;
  background: #ffffff;
  box-shadow: 0px -1.63673px 4.91018px rgba(141, 31, 168, 0.12),
    0px 1.63673px 6.54691px rgba(168, 85, 189, 0.14);
  border-radius: 9.82036px;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  bottom: 0px;
  top: 500px;
  left: 330px;
}
.Card_Title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 129%;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #000000;
}
.Req_btn {
  padding: 11px 40px !important;
  font-family: "Rubik" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13.3015px !important;
  line-height: 16px !important;
  text-align: center !important;
  letter-spacing: 0.035em !important;
  text-transform: capitalize !important;
  color: #ffffff !important;
  background: #81089f !important;
  border-radius: 65.2787px !important;
}
.price_qoutes {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 13.3015px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.035em;
  text-transform: capitalize;
  color: #000000;
}
.textarea {
  width: 81%;
  height: 150px;
  background: #ffffff;
  border: 0.6px solid rgba(0, 0, 0, 0.22);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12);
  border-radius: 7px;
}
.textarea:focus {
  outline: "none" !important;
  outline-color: #3d94f0;
}
.textarea::placeholder {
  padding: 15px 15px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 129%;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: rgba(151, 151, 151, 0.81);
}
.other_txt {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.035em;
  text-transform: capitalize;
  color: #000000;
}
.other_subtxt {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 23.1825px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.035em;
  text-transform: capitalize;
  color: #000000;
}
.contactform1 {
  height: 650px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(141, 31, 168, 0.17),
    0px -4px 8px rgba(255, 255, 255, 0.4);
  border-radius: 12px;
  margin-top: 100px;
  margin-bottom: 100px;
}
.contactus_des1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 159%;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #373737;
}
.attachment1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 129%;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color:#384D4B;
  cursor: pointer;
}
.email_input1 .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 0.6px solid rgba(0, 0, 0, 0.22) !important;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12);
  border-radius: 7px;
}
/* .email_input1 .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  margin-top: 7.5px !important;
} */
.email_input1
  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #1976d2 !important;
  border: 0.6px solid;
}
.contactus_txt1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16.2538px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.035em;
  text-transform: capitalize;
  color: #000000;
}
.otherproduct_card {
  background: #ffffff;
  box-shadow: 0px 3.6px 2.7px rgba(0, 0, 0, 0.14);
  border-radius: 6.3px;
  width: 80%;
  height: 340px;
}
.otherproductcard_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.035em;
  text-transform: capitalize;
  color: #3f3f3f;
  cursor: pointer;
}
.otherproductcard_title:hover {
  text-decoration: underline;
}
.otherproductcard_des {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.035em;
  text-transform: capitalize;
  color: #000000;
}

/* callback card */
.callbackcard {
  width: 60%;
  height: 360px;
  background: #ffffff;
  box-shadow: 0px -1.63673px 4.91018px rgba(141, 31, 168, 0.12),
    0px 1.63673px 6.54691px rgba(168, 85, 189, 0.14);
  border-radius: 9.82036px;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  bottom: 0px;
  top: 800px;
  left: 330px;
}
.callback_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 30px;
}

.mobile_input .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 0.6px solid rgba(0, 0, 0, 0.22) !important;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12);
  border-radius: 7px;
}
.mobile_input
  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #1976d2 !important;
  border: 0.6px solid;
}
.mobile_input .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  margin-top: 7.5px !important;
}
.Callback_btn {
  padding: 18px 100px !important;
  font-family: "Rubik" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13.3015px !important;
  line-height: 16px !important;
  text-align: center !important;
  letter-spacing: 0.035em !important;
  text-transform: capitalize !important;
  color: #ffffff !important;
  background: #81089f !important;
  border-radius: 65.2787px !important;
}

@media only screen and (max-width: 1200px) {
  .CARDS_1 {
    padding-bottom: 250px;
  }
  .CARDS_2 {
    padding-bottom: 300px;
  }
  .CARDS_4 {
    padding-bottom: 210px;
  }
}
@media only screen and (max-width: 1242px) {
  .Card_1 img {
    height: 800px;
  }
  .ENQUIRY_btn {
    padding: 12px 65px !important;
  }
  .callback_btn {
    padding: 12px 40px !important;
  }
  .otherproductcard_title {
    font-size: 14px;
  }
  .callbackcard {
    position: absolute;
    left: 230px;
  }
}
@media only screen and (max-width: 992px) {
  .otherproduct_card {
    width: 80%;
    margin-left: 60px;
  }
  .callbackcard {
    width: 70%;
    position: absolute;
    left: 160px;
  }
}
@media only screen and (max-width: 977px) {
  .Card_1 img {
    height: 760px;
  }
  .ENQUIRY_btn{
    padding: 12px 45px !important;
    font-size: 12px!important;
  }
  .callback_btn {
    padding: 12px 20px !important;
    font-size: 12px!important;

  }
  .Product_Exn {
    font-size: 15px;
  }

  .Product_req {
    font-size: 13px;
  }
  .Product_Points {
    font-size: 15px;
  }
  .share_txt {
    font-size: 15px;
  }
  .share {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .Pricecard {
    width: 87%;
    position: absolute;
    left: 60px;
  }
  .otherproduct_card {
    height: 320px;
  }
  .CARDS_3 {
    padding-bottom: 300px;
  }
  .Product_Detail {
    position: absolute;
    left: -25px;
  }
  .price_btn {
    padding: 15px 20px !important;
  }
  .Product_Name {
    font-size: 27px;
  }
  .CARDS_1 {
    padding-bottom: 350px;
  }
  .CARDS_2 {
    padding-bottom: 370px;
  }
  .CARDS_4 {
    padding-bottom: 390px;
  }
  .CARDS {
    padding-bottom: 180px;
  }
  .callbackcard {
    position: absolute;
    left: 140px;
  }
  .product_customize {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .Card_1 img {
    height: 500px;
  }
  .Product_Name {
    font-size: 20px !important;
    line-height: 30px;
  }
  .Square {
    font-size: 22px;
  }
  .Product_Exn {
    padding-right: 0px !important;
    font-size: 14px;
  }
  .Square_txt {
    font-size: 22px;
  }
  .price_btn {
    padding: 15px 30px !important;
    font-size: 15px !important;
  }

  .Pricecard {
    width: 94%;
    position: absolute;
    left: 10px;
    top: 1760px;
    height: 470px;
  }
  .email_input {
    margin-left: 0px !important;
  }
  .txt {
    margin-left: 24px !important;
  }
  .Card_Title {
    font-size: 16px;
    text-align: start;
    margin-left: 15px;
  }

  .otherproduct_card {
    width: 80%;
    margin-left: 30px;
  }
  .Product_Feature {
    margin-top: 20px !important;
  }
  .product_des {
    margin-top: 0px !important;
  }
  .Product_Title {
    margin-top: 10px !important;
  }

  .Product_Detail {
    padding: 0px 10px;
  }
  .ENQUIRY_btn {
    padding: 10px 35px !important;
    font-size: 13px !important;
  }
  .callback_btn {
    padding: 10px 15px !important;
    font-size: 12px !important;
  }
  .callbackcard {
    position: absolute;
    top: 1400px;
  }
  .Callback_btn {
    padding: 15px 70px !important;
  }
}
@media only screen and (max-width: 658px) {
  .Pricecard {
    position: absolute;
    top: 1400px;
  }
  .callback_title {
    font-size: 16px;
    line-height: 25px;
  }
  .callbackcard {
    width: 80%;
    position: absolute;
    left: 50px;
  }

  .product_customize {
    font-size: 12px;
  }
}
@media only screen and (max-width: 576px) {
  .Card_EMAIL {
    margin-left: 0px !important;
  }
  .Card_EMAIL1 {
    margin-left: 0px !important;
    margin-top: 20px;
  }
  .contactus_des1 {
    font-size: 17px !important;
    padding-left: 10px !important;
    padding-top: 15px !important;
  }
  .textarea {
    margin-left: 10px !important;
    width: 90%;
  }
  .textarea::placeholder {
    font-size: 9px;
  }
  .attachment1 {
    margin-left: 20px !important;
  }
  .otherproductcard_title {
    font-size: 17px;
  }
  .otherproductcard_des {
    font-size: 15px;
  }

  .Pricecard {
    height: 650px;
  }
  .otherproduct_card {
    height: 300px;
  }
  .Callback_btn {
    padding: 15px 45px !important;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 450px) {
  .Product_Exn {
    font-size: 13px;
  }
  .Product_Title {
    margin-top: 10px !important;
  }
  .Product_Feature {
    margin-top: 10px !important;
    font-size: 18px;
  }
  .Product_Points {
    font-size: 13px;
    line-height: 20px;
  }
  .Card_Title {
    font-size: 14px;
  }
  .Pricecard {
    position: absolute;
    top: 1170px;
    height: 670px;
  }
  .contactus_txt1 {
    font-size: 14px;
    margin-top: 26px !important;
  }
  .otherproductcard_title {
    font-size: 13px;
  }
  .otherproduct_card {
    width: 98%;
    height: 320px;
    margin-left: 0px !important;
  }
  .Card_Title {
    margin-top: 30px !important;
  }
  .callback_title {
    font-size: 14px;
  }
  .callbackcard {
    position: absolute;
    left: 20px;
    width: 90%;
    top: 1250px;
  }
}
@media only screen and (max-width: 400px) {
  .Product_Name {
    font-size: 22px;
    margin-left: 20px !important;
  }
  .Square {
    font-size: 20px;
    margin-left: 20px !important;
  }
  .Square_txt {
    font-size: 20px;
  }
  .price_btn {
    padding: 12px 20px !important;
  }
  .email_input1 {
    margin-top: 5px !important;
  }
  .Pricecard {
    height: 670px;
    position: absolute;
    top: 1130px;
  }
  .ShareProduct1 {
    position: absolute;
    right: 0px;
  }
  .other_txt {
    font-size: 25px;
  }
  .other_subtxt {
    font-size: 20px;
  }
  .contactform1 {
    height: 650px;
  }
  .callbackcard {
    position: absolute;

    top: 1200px;
  }
}
@media only screen and (max-width: 376px) {
  .ShareProduct1 {
    position: absolute;
    bottom: 100px;
  }
}
@media only screen and (max-width: 370px) {
  .ee {
    width: 40px;
  }
  .Card_Title {
    font-size: 12px;
  }
  .Pricecard {
    height: 670px;
    position: absolute;
    top: 1080px;
  }
}
@media only screen and (max-width: 320px) {
  .Product_Name {
    font-size: 20px;
  }
  .Square {
    font-size: 18px;
  }
  .Square_txt {
    font-size: 18px;
  }
  .Pricecard {
    height: 670px;
    position: absolute;
    top: 1000px;
  }
  .contactform1 {
    height: 690px;
  }
}
