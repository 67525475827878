@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

::-webkit-scrollbar {
  width: 1px!important;
  background: #384d4b;

}

/* Track */
::-webkit-scrollbar-track {
  background:  #384d4b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #384d4b!important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:  #384d4b!important;
}
body{
  overflow-x: hidden!important;
}

* {
  font-family: "Montserrat", sans-serif!important;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}