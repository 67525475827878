@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&display=swap");

.Navbar {
  background-image: url("../../assets/HomePage/homepage1.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: fit-content;

}


.NAVBAR {
  /* max-width: 2200px; */
  padding:  0px 40px;

}

.container {
  max-width: 1600px;
  padding: 0 0;
}
.Nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.logo {
 text-align: start;
}
 .del-logo{
  width: 120px;
  height: auto;
 }

.inquiry_btn {
  border-radius: 32px;
  font-family: "Rubik";
  font-style:normal;
  font-weight: 400;
  font-size: 12.2636px;
  line-height: 17px;
  background-color: #ffffff;
  padding: 5px 10px;
  color: black;
  text-decoration: none; 
  outline: none;

}
.menu{
  display: flex;
  justify-content: flex-end; 
  padding: 0;
  
}
.homepage_menu{
  margin-left: 360px;

}
.menu li.no-line::after {
  display: none;
}
.inquiry_btn:hover {
  color: black;
  text-decoration: none; 

}

.homepage_title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 45px;
  line-height: 129%;
  color: #ffffff;
  text-transform: uppercase;
}

.inputbase {
  padding: 10px 30px;
  background-color: #ffffff;
  border-radius: 32px;
}
.search_icon {
  color: #ffffff;
}
.iconbutton {
  background-color: #384D4B !important;
}
/* menulist */
.menu > li,
.menu > a {
  display: inline-block;

}
.menu li a {
 
  transition: background-color 0.22s ease, color 0.22s ease;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;
  color: white;
}


.menu li {
  padding: 0 20px;
  position: relative;}
.submenu {
  position: absolute;
  left: 33%; /* Centers the submenu horizontally based on the parent */
  transform: translateX(-50%); /* Shifts the submenu left by 50% of its width */
  display: none; /* Initially hidden */
  padding: 10px 0; /* Adjust padding as necessary */
  width: 180px; /* Define a specific width */
  background-color: #f6f5f5; /* White background */
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15); /* Soft shadow for better visibility */
  border-radius: 3px;
  z-index: 99;
  margin-top: 10px;
}

.submenu li {
  list-style: none;
}

.submenu li  a {
  display: block;
  color: #323232;
  padding: 4px 0px; /* Adjust padding to ensure uniform spacing */
  font-size: 12px;
  text-align: center;
}
.menu li:hover > .submenu {
  display: block; /* Show submenu on hover */
}

.submenu_2 {
  width: 200px; /* Define a specific width */

  position: absolute;
  top: 0px;
  left: 103%; /* Aligns next submenu to the right of the first submenu */
  display: none; /* Hidden by default */
  padding: 10px 0px;
  background-color: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  width: 100%;

}

.submenu_2 a {
  display: block;
  color: #f6f1f1;
  padding: 5px 0px;
  font-size: 10px;
  width: 100%;

}

.submenu_2 li {
  padding: 3px 2px; 
  width: 100%;

}
.menu li:hover > .submenu_2 {
  display: block;
}
.submenu li:after,
.submenu_2 li:after {
  content: none;
}

/* Submenu background hover (optional) */
.submenu li:hover,
.submenu_2 li:hover {
  background-color: #384d4b3f !important;
}




.menu > li:after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 2px;
  left: 50%;
  background-color: #ffffff;
  transform: translateX(-50%);
  transition: width 0.3s ease-in-out;
  border-radius: 25px;
}

/* Hover effect for main menu items */
.menu > li:hover:after {
  width: 90%;
}





/* .menu a:hover:after {
  width: 90%;
} */
/* card */
.card {
  background: #ffffff;
  box-shadow: inset 0px 4px 36px rgba(226, 114, 255, 0.38);
  border-radius: 28px;
  width: 70%;
  margin-bottom: 40px;
  border: none;
}
.card:hover .latest_txt {
  text-decoration: underline;
}
.megaphone {
  height: 150px;
}
.megaphone_txt {
  height: 35px;
  background: #9100b7;
  color: #ffffff;
  border-bottom-left-radius: 28px;
  border-bottom-right-radius: 28px;
}
.latest_txt {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  margin-top: 15px;
}

.cards {
  padding-top: 30px;
}

.cards_row {
  margin: 0 0;
  padding: 0 0;
  --bs-gutter-x: 0em;
}

/* mobile_navbar */
.menu_icon {
  color: #ffffff;
  margin: 0 0;
}
.iconbtn{
  margin: 0 0;
}
.topnavbar {
  background-color: #ffffff;
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease-in-out;
  z-index: 2;
  cursor: pointer;
  width: 100%;
}

.sub-menu {
  background-color:#384D4B;
  color: #ffffff;
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease-in-out;
  width: 100%;
  box-shadow: 0px -2px 8px rgba(255, 255, 255, 0.53);
}
.topnavbar.open {
  height: auto;
  overflow-y: scroll;
  width: 100%;
}
.nav-item span {
  display: flex;
  justify-content:flex-end;
  text-align: end;
}
.menuopenicon {
  /* position: absolute; */
  bottom: 1000px;
  font-size: 36px !important;
  color: #384D4B;
}


.sub-menu.open {
  height: 180px;
}
.menuopen_icon {
  transform: rotate(180deg);
  color:#384D4B;

}

.topnavbar-nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.submenu-item {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.submenu-link {
  display: block;
  color: #ffffff !important;
  padding: 25px 20px;
  text-decoration: none;
  transition: background-color 0.22s ease, color 0.22s ease;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 10px;
}

.nav-link {
  display: block;
  color: #384D4B !important;
  padding: 35px 20px;
  text-decoration: none;
  transition: background-color 0.22s ease, color 0.22s ease;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 10px;
  margin-top: 0px;
}
.nav-link:hover {
  text-decoration: underline;
}

/* search */

.search {
  max-width: 380px;
}
.search_autocomplete {
  position: absolute;
  /* top:490px;
  left: 500px; */
  cursor: pointer;
  z-index: 2;
  width: 200px;
  max-width: 200px;
  background-color: #ffffff;
  padding: 5px 0px;
  border-radius: 12px;
  box-shadow: 0px -2px 8px rgba(255, 255, 255, 0.53);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.search_autocomplete.arrow-top:after {
  content: " ";
  position: absolute;
  right: 110px;
  top: -9px;
  border-top: none;
  border-right: 11px solid transparent;
  border-left: 11px solid transparent;
  border-bottom: 11px solid white;
}
.inputbase.css-yz9k0d-MuiInputBase-input {
  padding: 4px 10px !important;
 text-align: start;
}

.search_list {
  font-weight: 300;
  font-size: 13px;
  list-style-type: none;
  cursor: pointer;
  text-align: start;
  line-height: 40px;
  font-family: "Rubik";
  color: #272727;
  padding-left: 10px;
}
.search_item {
  margin: 0px !important;
  padding: 0 0;
}
.search_list:hover {
  background: rgba(249, 223, 255, 0.18);
}
@media only screen and (max-width: 1300px){
  .homepage_menu{
    margin-left: 300px;
  
  }
}
@media only screen and (max-width: 1200px) {
  .homepage_menu{
    margin-left: 100px;
  
  }
  .menu li a {
    font-size: 12px;
    padding:0 0px;
  }
  .submenu a {
    font-size: 10px;
    padding: 5px 0px;
  }
  .submenu_2 a {
    font-size: 9px;
  }
  .menu {
    margin-top: 11px !important;
  }

  .inquiry_btn {
    margin-left: 0px !important;
    font-size: 12px;
  }
  .logo {
    font-size: 16px;
  }
  .card {
    width: 85%;
  }
  .card {
    margin-left: 20px !important;
  }
}
@media only screen and (max-width: 1000px) {
  /* .homepage_menu{
    margin-left: 100px;
  
  } */
  .NAVBAR {
    /* max-width: 2200px; */
    padding:  0px 20px;
  
  }
  .card {
    width: 90%;
  }
  .latest_txt {
    font-size: 18px;
  }
  .cards {
    padding: 30px 60px;
  }
  .homepage_title {
    font-size: 38px;
  }
  .Navbar {
    background-image: url("../../assets/HomePage/homepage1.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: fit-content;
  }
}

@media only screen and (max-width: 700px) {
  .del-logo{
    width: 100px;
    height: auto;
    margin-top: 4px;
   }
  .dropdown {
    position: absolute;
    right: 150px;
  }
  .homepage_title {
    font-size: 30px;
  }
  .card {
    width: 100%;
    margin-left: 0px !important;
  }
  .card_gap {
    --bs-gutter-x: 1em;
  }
  .cards {
    padding: 30px 30px;
  }
  .latest_txt {
    font-size: 16px;
  }
}
@media only screen and (max-width: 500px) {
  .homepage_title {
    font-size: 23px;
    margin-top: 0px !important;
    padding-top: 40px!important;
  }
  .megaphone{
    height: 100px;
  }
  .latest_img {
    width: 70px;
    margin-top: 20px !important;
  }
  .latest_img1 {
    width: 60px;
  }
  .latest_img2 {
    width: 50px;
  }
  .card {
    height: 130px;
    margin-bottom: 20px;
  }
  .cards {
    padding: 10px 10px;
  }
  
  .latest_txt {
    font-size: 11px;
    margin-top: 0px;
  }
  .logo {
    font-size: 14px;
  }
  .iconbtn {
    margin-right: 0px !important;
  }
  .search {
    margin-top: 20px !important;
  }
  .card_gap{
    margin-top: 30px!important;
  }
}
@media only screen and (max-width: 395px) {
  .homepage_title {
    font-size: 19px;
    margin-top: 20px !important;
  }
  .inputbase {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .search_icon {
    font-size: 17px !important;
  }
  .latest_txt {
    font-size: 9px;
  }
  .card {
    height: 110px;
  }
  .latest_img {
    margin-top: 10px !important;
    width: 60px;
  }
  .latest_img1 {
    margin-top: 15px !important;
    width: 50px;
  }
  .latest_img2 {
    margin-top: 12px !important;
    width: 40px;
  }

  .menu_icon {
    font-size: 30px !important;
  }
  .megaphone{
    height: 80px;
  }
  
}

@media only screen and (max-width: 320px) {
  .logo {
    font-size: 12px;
    line-height: 15px;
  }
  .menu_icon {
    font-size: 25px !important;
  }
  .homepage_title {
    font-size: 16px;
    line-height: 25px;
  }
  .cards {
    padding: 10px 0px;
  }
}
